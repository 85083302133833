import { match as about } from "../../../src/params/about.ts";
import { match as assets } from "../../../src/params/assets.ts";
import { match as contact } from "../../../src/params/contact.ts";
import { match as editorial } from "../../../src/params/editorial.ts";
import { match as privacy } from "../../../src/params/privacy.ts";
import { match as search } from "../../../src/params/search.ts";
import { match as sitemap } from "../../../src/params/sitemap.ts";
import { match as slug } from "../../../src/params/slug.ts";
import { match as terms } from "../../../src/params/terms.ts";
import { match as writers } from "../../../src/params/writers.ts";

export const matchers = { about, assets, contact, editorial, privacy, search, sitemap, slug, terms, writers };