import type { ParamMatcher } from '@sveltejs/kit';

export const match: ParamMatcher = (param) => {
	const paths = [
		'apple-touch-icon.png',
		'ads.txt',
		'favicon.ico',
		'icon-192.png',
		'icon-512.png',
		'icon.svg',
		'logo.png',
		'logo.svg',
		'og.jpg'
	];

	return paths.includes(param);
};
