export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13')
];

export const server_loads = [0];

export const dictionary = {
		"/": [~3],
		"/informations/[page=writers]": [~12,[2]],
		"/informations/[page=terms]": [~11,[2]],
		"/informations/[page=privacy]": [~10,[2]],
		"/informations/[page=editorial]": [~9,[2]],
		"/informations/[page=contact]": [~8,[2]],
		"/informations/[page=about]": [~7,[2]],
		"/informations/[page=writers]/[writer=slug]": [~13,[2]],
		"/[search=search]": [~6],
		"/[category]": [~4],
		"/[category]/[article]": [~5]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';